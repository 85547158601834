import React from 'react';

function Youtube({videoId, autoplay}:{
    videoId: string,
    autoplay?: boolean
}){
        return (
            <div
                className="video"
                style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                }}
            >
                <iframe id="ytplayer" style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }} src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay === undefined ? 1 : autoplay}`} title={"Youtube video"} allow={"fullscreen; autoplay;"} frameBorder="0"/>
            </div>
        );
}

export default Youtube;
